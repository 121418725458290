<template>
  <v-text-field
    v-model="propModel"
    class="smb-input"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependInnerIcon"
    :placeholder="placeholder"
    outlined
    @keydown.enter.prevent="toggleMarker"
    :prefix="prefix"
    :ref="refProp"
    :label="label"
    :type="type"
    :error-messages="errorMessages"
    background-color="#fff"
    :rules="rules"
    @click:append="toggleMarker"
  >
    <template v-slot:label>
      <slot name="description"></slot>
    </template>
    <template v-if="isLoading" v-slot:append>
      <v-fade-transition leave-absolute>
        <v-progress-circular
          size="24"
          color="info"
          indeterminate
        ></v-progress-circular> </v-fade-transition
    ></template>
  </v-text-field>
</template>

<script>
export default {
  name: "SmbInput",
  props: {
    appendIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    valueProp: {
      type: [String, Array, Object],
      default: "",
    },
    refProp: {
      type: String,
      default: "smbListing",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "text",
    },
    prefix: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    propModel: {
      get() {
        return this.valueProp;
      },
      set(value) {
        this.$emit("propWasUpdated", value);
      },
    },
  },
  methods: {
    toggleMarker() {
      if (!this.isLoading) {
        this.$emit("clickedOnSendListingBtn");
        this.$refs.smbListing.blur();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.smb-input {
  ::v-deep {
    .v-icon {
      color: #000000;
    }
    .v-text-field__slot {
      width: 100%;
      label {
        width: 100%;
        max-width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #1d1d1b;
        top: 15px;
      }
      .zillow-link {
        display: flex;
        align-items: baseline;
        strong {
          font-size: 19px;
          margin-right: 4px;
          font-weight: 600;
        }
        span {
          font-size: 10px;
          font-weight: 600;
        }
      }
      input {
        color: #1d1d1b;
      }
    }
    .v-label--active {
      display: none;
    }
    .v-input__prepend-inner {
      .mdi-link-variant {
        font-size: 18px;
      }
    }
    fieldset {
      border-color: #000000 !important;
      legend {
        width: 0px !important;
      }
    }
    .v-input__slot {
      min-height: 50px;
      margin-top: 10px;
      .v-input__append-inner,
      .v-input__prepend-inner {
        margin-top: 13px;
      }
    }
    .v-text-field__details {
      .v-messages {
        color: red;
      }
    }
  }
}
</style>
