<template>
  <v-combobox
    :delimiters="[';']"
    @change="chipInput($event)"
    v-model="chipsListComputed"
    chips
    hide-details
    background-color="#FFFFFF"
    class="tags_container"
    multiple
    outlined
  >
    <template v-slot:selection="{ attrs, item, index, select, selected }">
      <draggable
        :id="index"
        :list="chipsListComputed"
        v-bind="dragOptionsChips"
        :move="move"
        @change="change($event)"
        handle=".v-chip__content strong"
      >
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          color="#7e5689"
          small
          draggable
          class="txt-color"
          @mousedown.stop
          @click.stop
          @click="select"
          @click:close="deleteChipHandler(item)"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </draggable>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "SmbChips",
  props: {
    chipsList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
    chipsListComputed: {
      get() {
        return JSON.parse(JSON.stringify(this.chipsList));
      },
      set(newHashtags) {
        this.$emit("changeChipsList", newHashtags);
      },
    },
  },
  methods: {
    chipInput(val) {
      if (!val && !val.length) return;
      this.chipsListComputed = val;
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change(value) {
      if (value.removed) {
        this.chipsListComputed.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.chipsListComputed[this.dragged.from]
        );
        if (this.dragged.from < this.dragged.to)
          this.chipsListComputed.splice(this.dragged.from, 1);
        else this.chipsListComputed.splice(this.dragged.from + 1, 1);

        this.$emit("changeChipsList", this.chipsListComputed);
      }
    },
    deleteChipHandler(tag) {
      this.chipsListComputed = this.chipsListComputed.filter(
        (val) => val !== tag
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tags_container {
  .v-input__append-inner {
    display: none !important;
  }
}
</style>
