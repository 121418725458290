<template>
  <div class="button-item">
    <v-btn
      class="btn-text"
      :class="customClass"
      :disabled="isDisabled"
      height="55"
      width="100%"
      color="#1D1D1B"
      :loading="isLoading"
      @click="$emit(emitName)"
    >
      <v-icon v-if="isUseIcon">{{ iconName }}</v-icon>
      <span class="text" v-if="text.length"> {{ text }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SmbActionBtn",
  props: {
    text: {
      type: String,
      default: "",
    },
    emitName: {
      type: String,
      default: "click",
    },
    isUseIcon: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.button-item {
  margin-top: auto;

  .btn-text {
    border-radius: 0;
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    justify-content: center;
    text-transform: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
