<template>
  <div>
    <v-textarea
      v-model="propModel"
      height="250"
      outlined
      :placeholder="placeholder"
      background-color="#ffff"
      name="input-7-4"
      :error-messages="errorMessages"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: "SmbTextarea",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    valueProp: {
      type: [String, Array, Object],
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    propModel: {
      get() {
        return this.valueProp;
      },
      set(value) {
        this.$emit("propWasUpdated", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-label--active {
    display: none;
  }
  fieldset {
    border-color: #000000;
    legend {
      width: 0px !important;
    }
  }
}
</style>
