<template>
  <div class="smb-title">
    <div
      v-if="isShowTitle"
      class="smb-title__title"
      :class="{ center: alignCenter }"
    >
      {{ title }}
    </div>
    <div v-if="isShowSubTitle" class="smb-title__sub-title">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SmbTitles",
  props: {
    isShowTitle: {
      type: Boolean,
      default: false,
    },
    isShowSubTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.smb-title {
  padding: 0 16px 12px 16px;
  background: #fff;
  @media screen and (min-width: 760px) {
    padding: 14px 16px 14px 16px;
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #1f2325;
    @media screen and (max-width: 1500px) {
      font-size: 20px;
    }
    &.center {
      text-align: center;
    }
  }
  &__sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #1f2325;
    margin-top: 11px;
    &.center {
      text-align: center;
    }
  }
}
</style>
