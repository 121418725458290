<template>
  <div class="smb-client-wrapper">
    <SmbTitles is-show-title is-show-sub-title title="Settings & Profile" />
    <div class="form-wrapper">
      <div class="control area-container">
        <div class="control__label">Area</div>
        <smb-chips
          :chipsList="area"
          @changeChipsList="changeChipsListHandler($event)"
        />
      </div>
      <div class="control">
        <div class="control__label">About me</div>
        <SmbTextarea
          :value-prop="aboutMe"
          placeholder="About me"
          @propWasUpdated="aboutMe = $event"
        />
      </div>
      <div class="control">
        <div class="control__label">Website</div>
        <SmbInput
          :value-prop="websiteSaas"
          :error-messages="
            !!websiteSaas && !isValidationDoneForWebsite
              ? [
                  'Please enter a valid Website URL (e.g. https://social-wonder.com)',
                ]
              : []
          "
          placeholder="Website"
          @propWasUpdated="websiteSaas = $event"
        />
      </div>
    </div>
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isSaveBtnLoading"
        :text="isProfileChanged ? 'Save' : 'Cancel'"
        :emitName="isProfileChanged ? 'save' : 'cancel'"
        @cancel="$router.push({ name: 'profile-settings' })"
        @save="saveHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbInput from "@/components/smb-client/SmbInput";
import SmbTextarea from "@/components/smb-client/SmbTextarea.vue";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbChips from "@/components/smb-client/SmbChips.vue";

export default {
  name: "SmbBusinessInfo",
  data: () => ({
    area: "",
    aboutMe: "",
    isLoading: false,
    isSaveBtnLoading: false,
    websiteSaas: "",
  }),
  components: {
    SmbChips,
    SmbTitles,
    SmbInput,
    SmbActionBtn,
    SmbTextarea,
  },
  created() {
    this.setFieldsValue();
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isProfileChanged() {
      return (
        this.area !== this.getCurrentSmbCustomer.area ||
        this.aboutMe !== this.getCurrentSmbCustomer.about_client ||
        (this.websiteSaas !== this.getCurrentSmbCustomer.website &&
          this.isValidationDoneForWebsite) ||
        (!this.websiteSaas &&
          this.websiteSaas !== this.getCurrentSmbCustomer.website)
      );
    },
    isValidationDoneForWebsite() {
      return this.isLink(this.websiteSaas);
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),

    setFieldsValue() {
      this.area = this.getCurrentSmbCustomer.area.trim()
        ? this.getCurrentSmbCustomer.area.split(";")
        : [];
      this.aboutMe = this.getCurrentSmbCustomer.about_client;
      this.websiteSaas = this.getCurrentSmbCustomer.website;
    },
    isLink(text) {
      const expression =
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[^\s]*)?$/;
      const regex = new RegExp(expression);
      return regex.test(text);
    },
    async saveHandler() {
      this.isSaveBtnLoading = true;
      await this.updateCustomer({
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          customerId: this.getCurrentSmbCustomer.id,
          areaSaas:
            this.area !== this.getCurrentSmbCustomer.area
              ? this.area.join("; ") || " "
              : undefined,
          about_client:
            this.aboutMe !== this.getCurrentSmbCustomer.about_client
              ? this.aboutMe
              : undefined,
          websiteSaas:
            this.websiteSaas !== this.getCurrentSmbCustomer.website
              ? this.websiteSaas
              : undefined,
        },
      });
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.setFieldsValue();
      this.isSaveBtnLoading = false;
      await this.$router.push({ name: "profile-settings" });
    },
    changeChipsListHandler(newArea = []) {
      this.area = newArea;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;

  .form-wrapper {
    padding: 21px 20px 10px 20px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and (min-width: 760px) {
      flex: 0;
    }
    .area-container {
      padding-bottom: 20px;
    }
    .control {
      display: flex;
      flex: 0;
      flex-direction: column;
      &__label {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
      }
      .v-input {
        ::v-deep {
          .v-input__slot {
            min-height: 53px;
            fieldset {
              border: 1px solid #c7c9ca !important;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    @media screen and (min-width: 760px) {
      margin-top: 50px;
      max-width: 500px;
      padding: 0 20px 10px 20px;
      justify-content: flex-end;
    }

    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 313px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      @media screen and (min-width: 760px) {
        display: none;
      }
    }
  }
}
</style>
